import axios from "axios";

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

// Função para adicionar atraso
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Interceptor para adicionar atraso entre requisições
axios.interceptors.request.use(async (config) => {
  await delay(100); // Adiciona um atraso de 100ms entre as requisições
  return config;
});

class APIClient {
  getLoggedinUser = () => {
    const user = localStorage.getItem("authUser");
    if (!user) {
      return null;
    } else {
      return JSON.parse(user);
    }
  };

  contemErrorCode = (str) => {
    return (
      str.includes(" 401") ||
      str === "Request failed with status code 401" ||
      str.includes("Unauthenticated")
    );
  };

  /**
   * Fetches data from given url
   */
  get = async (url, params) => {
    const authUser = this.getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.get(url, { params, headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: this.contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  /**
   * post given data to url
   */
  post = async (url, data, additionalHeaders = null) => {
    const authUser = this.getLoggedinUser();
    let headers;
    if(additionalHeaders){
      headers = additionalHeaders;
      headers.Authorization = `Bearer ${authUser?.token}`;
    }else{
      headers = {
        Authorization: `Bearer ${authUser?.token}`,
      };
    }

    try {
      const response = await axios.post(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      console.error(mensagemErro);
      return {
        mensagem: this.contemErrorCode(mensagemErro)
          ? mensagemErro === "Network Error" ||
            mensagemErro === "Unauthenticated."
            ? ""
            : mensagemErro
          : mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  /**
   * post for download endpoints
   */
  postDownload = async (url, data) => {
    const authUser = this.getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.post(url, data, { 
        headers,
        responseType: 'blob'
      });
      
      return {
        sucesso: true,
        responseCode: response.status,
        dados: response.data,
        headers: response.headers,
      };
    } catch (error) {
      console.error(error);
      return {
        sucesso: false,
        responseCode: error.response?.status || 500,
        mensagem: "Erro ao baixar o arquivo.",
      };
    }
  };

  /**
   * Updates data
   */
  update = async (url, data) => {
    const authUser = this.getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.patch(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: this.contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  put = async (url, data) => {
    const authUser = this.getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.put(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: this.contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  /**
   * Delete
   */
  delete = async (url, config) => {
    const authUser = this.getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.delete(url, { ...config, headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: this.contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: this.contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };
}

export { APIClient };
