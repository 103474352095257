import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const ModalAnuncio = ({ modal, toggleModal, anuncio }) => {
  return (
    <Modal isOpen={modal} toggle={() => toggleModal()} size="lg">
      <ModalHeader toggle={() => toggleModal()}>{anuncio?.titulo}</ModalHeader>
      <ModalBody>
        {anuncio?.imagens?.length > 0 && (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{ clickable: true }}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            className="mySwiper swiper navigation-swiper rounded"
          >
            <div className="swiper-wrapper">
              {anuncio?.imagens.map((imagem, index) => {
                return (
                    // colocar centralizado na vertical também 
                  <SwiperSlide
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                    style={{ 
                        maxHeight: "300px", 
                    }}
                  >
                    <img
                      src={imagem.base64}
                      alt={`img-${index}`}
                      className="img-fluid"
                      style={{ 
                        maxHeight: "300px", objectFit: "cover", margin: "auto"}}
                    />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        )}
        <Row className="mt-3">
            {/* aumentar tamanho da fonte */}
          <div dangerouslySetInnerHTML={{ __html: anuncio?.texto }} 
          style={{ fontSize: "1rem" }}
          />
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalAnuncio;
